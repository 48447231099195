// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.navbar {
  width: 100%;
  background-color: #255799;
  padding: 10px 20px;
  display: flex;
  font-family: "Montserrat";
  /* font-family: Helvetica, Arial, Sans-Serif; */
  justify-content: flex-end;
  position: relative;
  z-index: 100;
  box-shadow: 0 0 30px rgba(58, 58, 58, 0.7);
}

.right-aligned-links {
  display: flex;
}

.nav-item {
  text-decoration: none;
  font-size: calc(0.6vw + 0.75rem);
  /* color: #1e1e1e; */
  color: white;
  margin-right: 50px;
  font-family: "Montserrat";
}

.special-item {
  font-size: calc(1.5vw + 1rem);
  font-weight: bold;
  margin-right: auto;
}

.nav-item:hover {
  text-decoration: underline;
}

.special-item:hover {
  text-decoration: none;
  /* color: black; */
}

#LogOut{
  border-radius: 5px;
  /* background-color:  #bdbdbd; */
  background-color:  #FECC07;
  cursor: pointer;
  align-self: center;
  color: black;
}

#LogOut:hover{
  /* background: #b1995f; */
  background: #FFDD45;
}

#UCIBold{
  font-weight: 850;  
  /* font-family: "Monserrat Bold"; */
}

#UCINoBold{
  font-weight: 550;
}

#currentProjectsTab{
  align-self: center;
}
`, "",{"version":3,"sources":["webpack://./src/css/NavBar.css"],"names":[],"mappings":";AACA;EACE,WAAW;EACX,yBAAyB;EACzB,kBAAkB;EAClB,aAAa;EACb,yBAAyB;EACzB,+CAA+C;EAC/C,yBAAyB;EACzB,kBAAkB;EAClB,YAAY;EACZ,0CAA0C;AAC5C;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,qBAAqB;EACrB,gCAAgC;EAChC,oBAAoB;EACpB,YAAY;EACZ,kBAAkB;EAClB,yBAAyB;AAC3B;;AAEA;EACE,6BAA6B;EAC7B,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,0BAA0B;AAC5B;;AAEA;EACE,qBAAqB;EACrB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,gCAAgC;EAChC,0BAA0B;EAC1B,eAAe;EACf,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,yBAAyB;EACzB,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;EAChB,mCAAmC;AACrC;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["\r\n.navbar {\r\n  width: 100%;\r\n  background-color: #255799;\r\n  padding: 10px 20px;\r\n  display: flex;\r\n  font-family: \"Montserrat\";\r\n  /* font-family: Helvetica, Arial, Sans-Serif; */\r\n  justify-content: flex-end;\r\n  position: relative;\r\n  z-index: 100;\r\n  box-shadow: 0 0 30px rgba(58, 58, 58, 0.7);\r\n}\r\n\r\n.right-aligned-links {\r\n  display: flex;\r\n}\r\n\r\n.nav-item {\r\n  text-decoration: none;\r\n  font-size: calc(0.6vw + 0.75rem);\r\n  /* color: #1e1e1e; */\r\n  color: white;\r\n  margin-right: 50px;\r\n  font-family: \"Montserrat\";\r\n}\r\n\r\n.special-item {\r\n  font-size: calc(1.5vw + 1rem);\r\n  font-weight: bold;\r\n  margin-right: auto;\r\n}\r\n\r\n.nav-item:hover {\r\n  text-decoration: underline;\r\n}\r\n\r\n.special-item:hover {\r\n  text-decoration: none;\r\n  /* color: black; */\r\n}\r\n\r\n#LogOut{\r\n  border-radius: 5px;\r\n  /* background-color:  #bdbdbd; */\r\n  background-color:  #FECC07;\r\n  cursor: pointer;\r\n  align-self: center;\r\n  color: black;\r\n}\r\n\r\n#LogOut:hover{\r\n  /* background: #b1995f; */\r\n  background: #FFDD45;\r\n}\r\n\r\n#UCIBold{\r\n  font-weight: 850;  \r\n  /* font-family: \"Monserrat Bold\"; */\r\n}\r\n\r\n#UCINoBold{\r\n  font-weight: 550;\r\n}\r\n\r\n#currentProjectsTab{\r\n  align-self: center;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
