import './css/PageNavigatonBar.css'
export const RESULTS_PER_PAGE = 10;

export function PageNavigationBar({ numResults, currentPage, setCurrentPage }) {
    const prevPage = () => {
        setCurrentPage(currentPage - 1);
    }
    const nextPage = () => {
        setCurrentPage(currentPage + 1);
    };
    const setPage = (pageNum) => {
        return (() => { setCurrentPage(pageNum - 1) });
    }

    return (
        <div className="page-selector">

            {/* PREVIOUS */}
            <button
                disabled={currentPage === 0}
                onClick={prevPage}
                className="page-selector-button"
            >
                &lt;&nbsp;&nbsp;Previous
            </button>

            {/* FIRST */}
            <button
                hidden={currentPage === 0}
                className={"page-selector-button"}
                onClick={setPage(1)}
            >
                1
            </button>

            {(currentPage) > 3 ? <span className="ellipsis">...</span> : ""}

            {/* PREV 2 */}
            {[currentPage - 2, currentPage - 1].map(x =>
                x >= 1 ? (
                    <button
                        className={"page-selector-button"}
                        onClick={setPage(x + 1)}
                    >
                        {x + 1}
                    </button>
                ) : ""
            )}

            {/* CURRENT */}
            <button
                disabled={true}
                className={"page-selector-button selected-page-button"}
                onClick={setPage(1)}
            >
                {currentPage + 1}
            </button>

            {/* NEXT 2 */}
            {[currentPage + 1, currentPage + 2].map(x =>
                (x + 1) < Math.ceil(numResults / RESULTS_PER_PAGE) ? (
                    <button
                        className={"page-selector-button"}
                        onClick={setPage(x + 1)}
                    >
                        {x + 1}
                    </button>
                ) : ""
            )}

            {(currentPage + 4) < Math.ceil(numResults / RESULTS_PER_PAGE) ? <span className="ellipsis">...</span> : ""}

            {/* LAST */}
            <button
                hidden={(currentPage + 1) * RESULTS_PER_PAGE >= numResults}
                className="page-selector-button"
                onClick={setPage(Math.ceil(numResults / RESULTS_PER_PAGE))}
            >
                {Math.ceil(numResults / RESULTS_PER_PAGE)}
            </button>

            {/* NEXT */}
            <button
                disabled={(currentPage + 1) * RESULTS_PER_PAGE >= numResults}
                onClick={nextPage}
                className="page-selector-button"
            >
                Next&nbsp;&nbsp;&gt;
            </button>
        </div>
    );
}

export default PageNavigationBar;