import React, { useState, useEffect, useRef } from "react";
import "./css/PeopleInput.css";

const PeopleInput = ({ title, validateName, validateEmail, placeholders, group, onPeopleChange }) => {
  const [expanded, setExpanded] = useState(false);
  const [people, setPeople] = useState(group);
  const [newPerson, setNewPerson] = useState({
    name: "",
    email: "",
    field3: "",
  });
  const [lastFieldChanged, setLastFieldChanged] = useState("");

  // Refs for the input fields for focusing
  const nameRefs = useRef([]);
  const emailRefs = useRef([]);
  const field3Refs = useRef([]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleInputChange = (index, e) => {
    const { name, value } = e.target;
    const updatedPeople = [...people];
    updatedPeople[index][name] = value;

    if (Object.values(updatedPeople[index]).every((val) => val === "")) {
      updatedPeople.splice(index, 1);
    }

    setPeople(updatedPeople);
    onPeopleChange(updatedPeople);
  };

  const handleNewPersonChange = (e) => {
    const { name, value } = e.target;
    setNewPerson((prev) => ({ ...prev, [name]: value }));
    setLastFieldChanged(name); // Track the last field changed, used for equivalent focusing
  };

  const addPerson = () => {
    if (Object.values(newPerson).every((val) => val !== "")) {
      const updatedPeople = [...people, newPerson];
      setPeople(updatedPeople);
      setNewPerson({ name: "", email: "", field3: "" });
      onPeopleChange(updatedPeople);

      // Focus on the equivalent input of the newly added row
      setTimeout(() => {
        const index = updatedPeople.length - 1;
        if (lastFieldChanged === "name" && nameRefs.current[index]) {
          nameRefs.current[index].focus();
        } else if (lastFieldChanged === "email" && emailRefs.current[index]) {
          emailRefs.current[index].focus();
        } else if (lastFieldChanged === "field3" && field3Refs.current[index]) {
          field3Refs.current[index].focus();
        }
      }, 0);
    }
  };

  useEffect(() => {
    setPeople(group);
  }, [group]);

  useEffect(() => {
    if (newPerson.name && newPerson.email && newPerson.field3) {
      addPerson(); // Automatically add new person when all fields of newPerson are filled
    }
  }, [newPerson]);

  return (
    <div className={`people-input ${expanded ? "expanded" : ""}`}>
      <div
        className={`title${expanded ? " expanded" : ""}`}
        onClick={handleExpandClick}
      >
        {title}
      </div>
      {expanded && (
        <div className="input-fields">
          {people.map((person, index) => (
            <div className="person-row text-center input-group" key={index}>
              <input
                type="text"
                name="name"
                value={person.name}
                placeholder={placeholders.name}
                onChange={(e) => handleInputChange(index, e)}
                className={`form-control ${person.name && validateName(person.name) ? "is-valid" : ""}`}
                required
                ref={(el) => (nameRefs.current[index] = el)}
              />
              <input
                type="text"
                name="email"
                value={person.email}
                placeholder={placeholders.email}
                onChange={(e) => handleInputChange(index, e)}
                className={`form-control ${person.email && validateEmail(person.email)? "is-valid" : ""}`}
                required
                ref={(el) => (emailRefs.current[index] = el)}
              />
              <input
                type="text"
                name="field3"
                value={person.field3}
                placeholder={placeholders.field3}
                onChange={(e) => handleInputChange(index, e)}
                className={`form-control ${person.field3 ? "is-valid" : ""}`}
                required
                ref={(el) => (field3Refs.current[index] = el)}
              />
            </div>
          ))}
          <div className="person-row text-center input-group">
            <input
              type="text"
              name="name"
              value={newPerson.name}
              placeholder={placeholders.name}
              onChange={handleNewPersonChange}
              className={`form-control ${newPerson.name && validateName(newPerson.name) ? "is-valid" : ""}`}
            />
            <input
              type="text"
              name="email"
              value={newPerson.email}
              placeholder={placeholders.email}
              onChange={handleNewPersonChange}
              className={`form-control ${newPerson.email && validateEmail(newPerson.email)? "is-valid" : ""}`}
            />
            <input
              type="text"
              name="field3"
              value={newPerson.field3}
              placeholder={placeholders.field3}
              onChange={handleNewPersonChange}
              className={`form-control ${newPerson.field3 ? "is-valid" : ""}`}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PeopleInput;