import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from "react-router-dom";
import './css/ResultsPage.css';

function ProjectDetailPage() {
  const { project_name } = useParams();
  const [project, setProject] = useState(null);

  useEffect(() => {
    const fetchProject = async () => {
      try {
        console.log(project_name);
        const url = `https://icscapstonearchive.org/api/projects/${project_name}/exact_match/true/tags/null/quarter/null/year/null/department/null/course_number/null/instructor/null/course_code/null`;
        const response = await axios.get(url);
        console.log(response);
        if (response && Array.isArray(response.data) && response.data.length > 0) {
          setProject(response.data[0]); // Assuming the first project is the desired one
        } else {
          console.error("Error fetching project: Invalid response data");
        }
      } catch (error) {
        console.error("Error fetching project:", error);
      }
    };

    fetchProject();
  }, [project_name]);

  if (!project) {
    return <div>Loading...</div>;
  }

  return (
    <div className="results-page">
      <h1 className="mb-3">{project.project_name}</h1>
      <div className="row-container" >

        <div className="column">
          <div className="row-6 mb-2">
            <b>{project.course.course_name +
              ", " +
              project.course.quarter + 
              " " + 
              project.course.year }</b>
          </div>

          <div className="row-6 mb-2">
            <b>Course Code: </b> {project.course.course_code || "N/A"}
          </div>
          <div className="row-6">
            <div className="mb-2">
              <b>Instructor: </b> {project.course.professor.name.first + " " + project.course.professor.name.last || "N/A"}
            </div>
            <div className="mb-2 ml-3 needsIndent">
              <b>Department: </b> {project.course.professor.department || "N/A"}
            </div>
            <div className="mb-2 ml-3 needsIndent">
              <b>Email: </b> {project.course.professor.email || "N/A"}
            </div>
          </div>
        </div>


        <div className="column">
          <div className="row-6">
              <div className="mb-2" >
                <b>Partner: </b> {project.partner.company_name || "N/A"}
              </div>
              <div className="mb-2 ml-3 needsIndent">
                <b>Department: </b> {project.primary_contact.department || "N/A"}
              </div>
              <div className="mb-2 ml-3 needsIndent">
                <b>Department Email: </b> {project.partner.email || "N/A"}
              </div>
              <div className="mb-2 ml-3 needsIndent">
                <b>Website: </b>
                <a href={project.partner.company_website || "N/A"}>
                  {project.partner.company_website || "N/A"}
                </a>
            </div>
          </div>
          
          <div className="row-6">
              <div className="mb-2">
                <b>Primary Contact: </b> {project.primary_contact.name.first + " " +  project.primary_contact.name.last|| "N/A"}
              </div>
              <div className="mb-2 ml-3 needsIndent">
                <b>Email: </b> {project.primary_contact.email || "N/A"}
              </div>
          </div>
        </div>
      </div>

      {/* VIDEO SECTION */}
      <div>
        <div className="video-row" >
          <div className="videoColumnFull">
            <div className="video-container">
              {/* only render the video element if a video exists */}
              {project.videos && project.videos.length > 0 ? (
                <video
                  src={project.videos}
                  controls={true}
                  autoPlay={false}
                ></video>
              ) : null}
            </div>
          </div>
          {project.deliverables ? (
          <div className="row m-5">
            <div className="text-right">
              <a href={project.deliverables} className="row btn deliverables-download" download>
                <span class="material-symbols-outlined fw-bold">
                download
                </span>
                <div className='col'>
                  Download Deliverables
                </div>
              </a>
            </div>
          </div>
        ) : null}
        </div>
      </div>


      <div className="row-container" >

        <div className="columnFull">
          <div className="row-6 mb-2">
            <b>Overview: </b> {project.overview}
          </div>
        </div>
      </div>

      <div className="row-container" >
        <div className="column">
          <div className="mb-2">
              <b>Student Team Members: </b>
              <div className="ml-3">
                {project.team_members.map((member, index) => (
                  <span key={index} class="needsIndent">
                    {member.name.first + " " +  member.name.last || "N/A"}, {member.major || "N/A"}
                    <br />
                  </span>
                ))}
              </div>
          </div>     
        </div>

        <div className="column">
          <div className="mb-2">
            <b>Partner Representatives: </b>
            <div className="ml-3">
              {project.representatives.map(
                (representative, index) => (
                  <span key={index} class="needsIndent">
                    {representative.name.first + " " + representative.name.first || "N/A"}, {representative.job_title || "N/A"}
                    <br />
                  </span>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProjectDetailPage;
