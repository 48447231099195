import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './css/NavBar.css'


function NavBar({ isLoggedIn, setLoggedInAnswer }) {
  const navigate = useNavigate();

  const handleLogout = () => {
    setLoggedInAnswer(false);
    /*localStorage*/sessionStorage.removeItem('isLoggedIn');
    navigate('/login');
  };

  return (
    
    <nav className="navbar">
      {/* <Link className="nav-item special-item" to="/">UCI Capstone Projects</Link>  */}
      <Link className="nav-item special-item" to="/"><span id="UCIBold">UCI</span></Link> 
      <div className="right-aligned-links">
        {isLoggedIn ? (
          <>
            <button className="nav-item" id="LogOut" onClick={handleLogout}>LOGOUT</button>
            <Link className="nav-item" to="/projects" id="currentProjectsTab">CURRENT PROJECTS</Link>
          </>
        ) : (
          <Link className="nav-item" to="/login">ADMIN LOGIN</Link>
        )}
        {/* <Link className="nav-item" to="/results">Results</Link> */}
      </div>
    </nav>
  );
}


export default NavBar;