import React, { useState, useEffect } from "react";
import "./css/PeopleInput.css";

function CourseInput({
  course = { name: "", code: "", quarter: "", year: "" },
  onCourseChange,
}) {
  const [expanded, setExpanded] = useState(false);
  const [internalCourse, setInternalCourse] = useState(course);

  useEffect(() => {
    setInternalCourse(course);
  }, [course]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedCourse = { ...internalCourse, [name]: value };
    setInternalCourse(updatedCourse);
    onCourseChange(updatedCourse);
  };

  return (
    <div className={`people-input ${expanded ? "expanded" : ""}`}>
      <div
        className={"title" + (expanded ? " expanded" : "")}
        onClick={handleExpandClick}
      >
        Course Details
      </div>
      {expanded && (
        <div className="input-fields">
          <div className="person-row text-center input-group">
            <input
              type="text"
              name="name"
              value={internalCourse.name}
              placeholder="course name, ex) In4matx 117"
              onChange={handleInputChange}
              className="form-control"
            />
            <input
              type="number"
              name="code"
              value={internalCourse.code}
              placeholder="course code, ex) 36430"
              onChange={handleInputChange}
              className="form-control"
            />
          </div>
          <div className="person-row text-center input-group">
            <select
              name="quarter"
              value={internalCourse.quarter}
              className="custom-select form-control"
              onChange={handleInputChange}
            >
              <option value="" disabled>
                quarter
              </option>
              <option value="Fall">Fall</option>
              <option value="Winter">Winter</option>
              <option value="Spring">Spring</option>
              <option value="Summer">Summer</option>
            </select>
            <input
              type="number"
              name="year"
              value={internalCourse.year}
              placeholder="year"
              onChange={handleInputChange}
              className="form-control"
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default CourseInput;
