import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import FiltersModal from './FiltersModal';
import { Link, useNavigate } from 'react-router-dom';
import './css/HomePage.css';
import homepageImage from './image/homepage.png';
// import DonaldBrenPic from './image/DonaldBrenPic.jpeg';

function HomePage() {
  const [projectTitle, setProjectTitle] = useState("");

  const [showFilters, setShowFilters] = useState(false);

  const [filterData, setFilterData] = useState({
    exactMatch: true,
    tags: [],
    quarter: "",
    year: "",
    department: "",
    courseNumber: "",
    instructor: "",
    courseCode: "",
  });

  // const handleApplyFilters = (filters) => {
  //   setFilterData(filters);
  // };

  const handleOpenFilters = () => setShowFilters(true);
  const handleCloseFilters = () => setShowFilters(false);

  const handleKeyDown = (e) => {
    if (e.key == "Enter") {
      e.preventDefault();
      handleSearch()
    }
  }

  const navigate = useNavigate();
  const handleSearch = () => {
    // construct utl
    let url = "/results?";
    let url_components = []
    if (projectTitle !== "") {
      url_components.push(`q=${encodeURIComponent(projectTitle)}`);
    }
    if (filterData.exactMatch) { // default = false
      url_components.push("exact_match=true");
    }
    if (filterData.tags.length > 0) {
      url_components.push(`tags=${encodeURIComponent(filterData.tags.join(","))}`);
    }
    if (filterData.quarter !== "") { // default = any
      url_components.push(`quarter=${encodeURIComponent(filterData.quarter)}`);
    }
    if (filterData.year !== "") { // default = any
      url_components.push(`year=${encodeURIComponent(filterData.year)}`);
    }
    if (filterData.department !== "") { // default = any
      url_components.push(`department=${encodeURIComponent(filterData.department)}`);
    }
    if (filterData.courseNumber !== "") { // default = any
      url_components.push(`course_number=${encodeURIComponent(filterData.courseNumber)}`);
    }
    if (filterData.instructor !== "") { // default = any
      url_components.push(`instructor=${encodeURIComponent(filterData.instructor)}`);
    }
    if (filterData.courseCode !== "") { // default = any
      url_components.push(`course_code=${encodeURIComponent(filterData.courseCode)}`);
    }

    url += url_components.join("&");

    navigate(url);
  };
  const goToLoginPage = () => {
    navigate("/login");
  };

  return (
    <div className="home-page-container"> {/* Main container with relative position */}

      <div className="background-container"></div>
      <div className="search-container">
        <div id="backgroundTitle"><span id="UCIBold">UCI </span><span id="UCINoBold">Capstone Projects</span></div>
        <button className="filter-button" onClick={handleOpenFilters}></button>
        <FiltersModal show={showFilters} onHide={handleCloseFilters} filterData={filterData} handleApplyFilters={setFilterData} />
        <input
          type="search"
          className="search-bar"
          placeholder="Project Title"
          onChange={(x) => setProjectTitle(x.target.value)}
          onKeyDown={handleKeyDown}
        />
        <button className="search-button" onClick={handleSearch} ></button>
      </div>

      {/* <div className="homepage-image-container">
        <img src={homepageImage} alt="Descriptive Alt Text" className="homepage-image" />
      </div> */}

      <div className="overview-container">
        <h3 className="overview-title">Program Overview and Goals</h3>
        <p className="overview-text">
          The Project in Informatics (IN4MATX 191A/B) and Project in Computer Science (COMPSCI180A/B) programs offer students a unique platform to apply their computer
          science knowledge to solve real-world problems, drawing from various disciplines such as informatics, statistics, and engineering.
          Working in teams, students select a project—sponsored by industry, non-profits, government, or research groups—to develop a solution
          and present their work, integrating advanced topics like artificial intelligence and the Internet of Things. Spanning two quarters,
          the courses focus initially on design and proof of concept, followed by final development, testing, and a professional presentation,
          honing technical and soft skills crucial for their future careers.
        </p>
      </div>

      <div className="homepage-image-container">
        <img src={homepageImage} alt="Descriptive Alt Text" className="homepage-image" />
      </div>


      <section className="">
        {/* <h2 className="contact-title">Contact</h2> */}
        <div className="contact-content">
          <h2 className="contact-title mt-5">Contact Us</h2>
          <div className="contact_1">
            <h4>Sergio Gago-Masagué</h4>
            <p>Director of CS Capstone Program</p>
            <p>Assistant Professor of Teaching, Computer Science</p>
            <p><a href="mailto:sgagomas@uci.edu">sgagomas@uci.edu</a></p>
          </div>
          <div className="contact_2">
            <h4>Mimi Anderson</h4>
            <p>Associate Director of Corporate Relations and Capstone Projects</p>
            <p><a href="mailto:yangmm@uci.edu">yangmm@uci.edu</a></p>
          </div>
          <div className="contact_3">
            <h4>Jason King</h4>
            <p>Director of Corporate Relations</p>
            <p><a href="mailto:jking5@uci.edu">jking5@uci.edu</a></p>
          </div>
        </div>
      </section>
    </div>
  );
}

export default HomePage;