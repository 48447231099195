import React from 'react';
import './css/Login.css';
import { useState } from 'react';
import axios from 'axios'; // Import axios for making HTTP requests
import { useNavigate } from 'react-router-dom';

function Login({ setLoggedInAnswer }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const checkAuth = async () => {
    try {
      const response = await axios.get('https://icscapstonearchive.org/api/auth');

      setLoggedInAnswer(true);
      /*localStorage*/sessionStorage.setItem('isLoggedIn', 'true');
      navigate('/projects');
    } catch (e) {
      console.log("Error Occurred");
      console.log(e);
    }
  };


  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      // Make POST request to login endpoint
      const response = await axios.post('https://icscapstonearchive.org/api/login', {
        email,
        password,
      });
      checkAuth()
    } catch (error) {
      console.log("Error Occurred");
      console.error({ e });

      if (error.response && error.response.data && error.response.data.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('An unexpected error occurred. Please try again.');
      }
    }
  };

  return (
    <div className="login-page-container">
      <header className="header">
      </header>
      <div className="login-container">
        <form className="login-form" onSubmit={handleLogin}>
          <h1 className="login-title"><span id="UCIBold">UCI </span><span id="UCINoBold">Capstone Projects</span></h1>

          {errorMessage && <div className="error-message">{errorMessage}</div>}

          <div className="input-container">
            <input type="email" className="input-field" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </div>

          <div className="input-container">
            <input type="password" className="input-field" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </div>

          <div className="actions-container">
            <button type="submit" className="site-button">Log In</button>
            {/* <a href="https://activate.uci.edu/cgi-bin/pwreset.cgi" className="forgot-password-link">Forgot Password?</a> */}
          </div>
        </form>
      </div>

      {/* <div className="signup-container">
        <p>Don’t have an Account? <a href="https://activate.uci.edu/activate/cgi-bin/activate.cgi" className="signup-link">Sign up</a></p>
      </div> */}
    </div >
  );
}

export default Login;