import React, { useState, useEffect } from "react";
import "./css/CurrentProjects.css";
import EditModal from "./EditModal.jsx";
import ProjectResult from "./ProjectResult.jsx";
import { PageNavigationBar, RESULTS_PER_PAGE } from "./PageNavigationBar.jsx";
import axios from "axios";
import { Button } from "react-bootstrap";

function CurrentProjects() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [results, setResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [selectedProject, setSelectedProject] = useState(null);

  const fetchCoursesAndProjects = async () => {
    try {
      const response = await axios.get("https://icscapstonearchive.org/api/courses");
      console.log("Response obj");
      console.log(response.data);
      if (response && Array.isArray(response.data)) {
        const newResults = response.data.flatMap((course) =>
          course.Projects.map((project) => ({
            ID: course.ID,
            course: {
              name: course.CourseName,
              code: course.CourseCode,
              quarter: course.Quarter,
              year: course.Year,
            },
            project_ID: project.ProjectID,
            project_name: project.ProjectName,
            team_members: project.TeamMembers ? project.TeamMembers : [],
            partner_name: project.Partner ? project.Partner.PartnerName : "N/A",
            partner_email: project.Partner
              ? project.Partner.PartnerDepartmentEmail
              : "N/A",
            partner_department: project.Partner
              ? `${project.Partner.PartnerDepartment}`
              : "N/A",
            company_website: project.Partner
              ? `${project.Partner.PartnerWebsite}`
              : "N/A",
            instructor: `${course.Professor}`,
            instructor_email: course.ProfessorEmail,
            instructor_dept: course.ProfessorDepartment,
            primary_contact: project.Partner
              ? `${project.Partner.PartnerRepresentative}`
              : "N/A",
            primary_contact_email: project.Partner
              ? `${project.Partner.PartnerEmail}`
              : "N/A",
            primary_contact_jt: project.Partner.PartnerJobTitle,
            code_repository: project.CodeRepository,
            code_repository_key: project.CodeRepositoryKey,
            deliverables: project.Deliverables,
            deliverables_key: project.DeliverablesKey,
            overview: project.Overview,
            videos: project.Videos ? project.Videos : [],
            video_key: project.VideoKey,
            representatives: project.Representatives
              ? project.Representatives
              : [],
          }))
        );
        newResults.sort((a, b) => a.project_name.localeCompare(b.project_name));
        setResults(newResults);
      } else {
        console.error("Error fetching courses: Invalid response data");
      }
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  useEffect(() => {
    fetchCoursesAndProjects();
  }, []);

  const deleteProject = (courseId, projectId) => {
    const updatedResults = results.filter(
      (project) => project.project_ID !== projectId
    );
    setResults(updatedResults);
    axios
      .delete(`https://icscapstonearchive.org/api/course/${courseId}/project/${projectId}`)
      .catch((error) => {
        console.error("Error deleting project:", error);
        fetchCoursesAndProjects();
      });
  };

  const editProject = async (courseId, projectId, projectData) => {
    const projectIndex = results.findIndex(
      (project) => project.project_ID === projectId
    );

    if (projectIndex !== -1) {
      const updatedProjects = [...results];
      const oldProjectData = updatedProjects[projectIndex];

      console.log("Old Project Data:");
      for (const [key, value] of Object.entries(oldProjectData)) {
        console.log(`${key}: ${value}`);
      }
      const formDataMap = new Map(projectData.entries());
      console.log("formDataMap:");
      for (let [key, value] of formDataMap) {
        console.log(`${key}: ${value}`);
      }

      const course = formDataMap.get("course");
      const teamMembers = formDataMap.get("team_members");
      const representatives = formDataMap.get("representatives");
      // const rep_array;
      // if (representatives) {
      //   for(let i = 0; i < representatives.length; i++) {

      updatedProjects[projectIndex] = {
        ...oldProjectData,
        project_name: formDataMap.get("projectName"),
        overview: formDataMap.get("overview"),
        partner_name: formDataMap.get("partner"),
        company_website: formDataMap.get("website"),
        partner_department: formDataMap.get("department"),
        instructor: formDataMap.get("instructorName"),
        instructor_email: formDataMap.get("instructorEmail"),
        instructor_dept: formDataMap.get("instructorDept"),
        primary_contact: formDataMap.get("primaryContactName"),
        primary_contact_email: formDataMap.get("primaryContactEmail"),
        primary_contact_jt: formDataMap.get("primaryContactJobTitle"),
        // repo_link: formDataMap.get("repoLink"),
        course: typeof course === "string" ? JSON.parse(course) : course,
        team_members:
          typeof teamMembers === "string"
            ? JSON.parse(teamMembers)
            : teamMembers,
        representatives: JSON.parse(formDataMap.get("representatives") || "[]"),
      };

      console.log("updatedProjects", updatedProjects[projectIndex]);
      setResults(updatedProjects);

      try {
        const response = await axios.put(
          `https://icscapstonearchive.org/api/course/${courseId}/project/${projectId}`,
          projectData
        );

        updatedProjects[projectIndex] = {
          ...updatedProjects[projectIndex],
          ...response.data,
          representatives: JSON.parse(formDataMap.get("representatives") || "[]"),
        };
        setResults(updatedProjects);
      } catch (error) {
        console.error("Failed to update project:", error);
        // Revert to old data if the update fails
        updatedProjects[projectIndex] = oldProjectData;
        setResults(updatedProjects);
      }
    } else {
      // add project instead
      try {
        const updatedProjects = [...results];

        console.log(projectData);
        const formDataMap = new Map(projectData.entries());
        const oldProjectData = updatedProjects[projectIndex];

        const course = formDataMap.get("course");
        const teamMembers = formDataMap.get("team_members");
        const representatives = formDataMap.get("representatives");
        updatedProjects.push({
          project_name: formDataMap.get("projectName"),
          overview: formDataMap.get("overview"),
          partner_name: formDataMap.get("partner"),
          company_website: formDataMap.get("website"),
          partner_department: formDataMap.get("department"),
          instructor: formDataMap.get("instructorName"),
          instructor_email: formDataMap.get("instructorEmail"),
          instructor_dept: formDataMap.get("instructorDept"),
          primary_contact: formDataMap.get("primaryContactName"),
          primary_contact_email: formDataMap.get("primaryContactEmail"),
          primary_contact_jt: formDataMap.get("primaryContactJobTitle"),
          partner_email: formDataMap.get("email"),
          // repo_link: formDataMap.get("repoLink"),
          course: typeof course === "string" ? JSON.parse(course) : course,
          team_members:
            typeof teamMembers === "string"
              ? JSON.parse(teamMembers)
              : teamMembers,
          representatives: JSON.parse(formDataMap.get("representatives") || "[]"),
        });
        setResults(updatedProjects);

        // updatedProjects[projectIndex] = {
        //   ...oldProjectData,
        //   project_name: formDataMap.get("projectName"),
        //   repo_link: formDataMap.get("repoLink"),
        //   overview: formDataMap.get("overview"),
        // }; // etc etc

        // setResults(updatedProjects);

        try {
          const response = await axios.post(
            `https://icscapstonearchive.org/api/projects`,
            projectData
          );

          updatedProjects[updatedProjects.length - 1] = {
            ...updatedProjects[updatedProjects.length - 1],
            videos: response.data.project.videos,
            deliverables: response.data.project.deliverables,
            code_repository: response.data.project.code_repository
          };
          console.log(updatedProjects[updatedProjects.length - 1]);
          setResults(updatedProjects);
          console.log("Project created successfully:", response.data);
        } catch (error) {
          if (error.response && error.response.status === 400) {
            updatedProjects.pop();
            setResults(updatedProjects);
            alert("Project already exists within the course");
          } else {
            console.error("Failed to add project:", error);
            updatedProjects[projectIndex] = oldProjectData;
            setResults(updatedProjects);
          }
        }
      } catch (error) {
        console.error("Failed to add project:", error);
      }
    }
  };

  const openEditModal = (projectData) => {
    setSelectedProject(projectData);
    setIsModalOpen(true);
  };

  function createProjectResult(projectData) {
    // NEED TO STORE REPRESENTATIVES IN PROJECT
    // projectData["representatives"] = [
    //   { name: "rep name 1", email: "rep email 1", field3: "rep job title 1" },
    //   { name: "rep name 2", email: "rep email 2", field3: "rep job title 2" },
    //   { name: "rep name 3", email: "rep email 3", field3: "rep job title 3" },
    // ];

    return (
      <ProjectResult
        {...projectData} // Spread all properties of projectData as props
        admin_view={true}
        deleteProject={deleteProject}
        editProject={editProject}
        openEditModal={() => openEditModal(projectData)} // Pass projectData to openEditModal
      />
    );
  }

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedProject(null); // Clear selected project on close
  };

  return (
    <div className="results-page">
      {" "}
      {/* Main container with relative position */}
      <EditModal
        show={isModalOpen}
        onSubmit={editProject}
        onClose={closeModal}
        projectData={selectedProject}
      />
      <h2>My Current Projects</h2>
      <Button onClick={openModal} id="AddProjectButton" className="mt-2" style={{ fontWeight: 'bold' }}>
        + ADD A NEW PROJECT
      </Button>
      <br />
      <br />
      <p>Click to view projects below:</p>
      <div>
        {results
          .slice(
            currentPage * RESULTS_PER_PAGE,
            (currentPage + 1) * RESULTS_PER_PAGE
          )
          .map((result, index) => (
            <div className="result-container">
              {createProjectResult(result)}
            </div>
          ))}
      </div>
      <PageNavigationBar
        numResults={results.length}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
      ></PageNavigationBar>
    </div>
  );
}

export default CurrentProjects;
