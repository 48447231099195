import React from "react";
import { Link } from "react-router-dom";
import codeIcon from "./image/codeIcon.png";
import "./css/ProjectResults.css";
import folderIcon from "./image/folderIcon.png";
import menuIcon from "./image/menuIcon.png";
import editIcon from "./image/editIcon.png";
import trashIcon from "./image/trashIcon.png";
import { Button } from "react-bootstrap";

export class ProjectResult extends React.Component {
  /*
    PROPS (passed in)
    USED:
      admin_view (to include more features)
      project_name
      team_members
      partner_name
      partner_department
      instructor
      instructor_email
      primary_contact
      primary_contact_email
      repo_link
      video_file
      overview
      deliverables // Add deliverables here to make it clear it's used
    UNUSED:
      course_name
      project_link
    */

  constructor(props) {
    super(props);
    console.log(props);
    this.state = { expanded: false };
    this.showHide = this.showHide.bind(this);
    // this.handleCodeClick = this.handleCodeClick.bind(this);
    this.handleFolderClick = this.handleFolderClick.bind(this);
    this.handleTrashClick = this.handleTrashClick.bind(this);
    this.handleEditClick = this.handleEditClick.bind(this);
  }

  // handleCodeClick(event) {
  //   event.stopPropagation();
  //   console.log("Code icon clicked");
  // }

  handleFolderClick(event) {
    event.stopPropagation();
    console.log("this.props",this.props);
    const url = this.props.deliverables;
    if (url) {
      // create an anchor element and trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.download = "";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      console.log("No deliverables URL provided");
    }
  }

  handleTrashClick(event) {
    if (window.confirm("Are you sure you want to delete this project?")) {
      this.props.deleteProject(this.props.ID, this.props.project_ID);
    }
  }

  handleEditClick(event) {
    event.stopPropagation();
    const Data = {
      course_ID: this.props.ID,
      project_ID: this.props.project_ID,
    };
    this.props.openEditModal(Data);
  }

  showHide() {
    this.setState((prevState) => ({
      expanded: !prevState.expanded,
    }));
  }

  render() {
    return (
      <div>
        {/* HEADER */}
        <div
          onClick={this.showHide}
          className={
            this.state.expanded
              ? "result-header expanded-result"
              : "result-header"
          }
        >
          <b>{this.props.project_name}</b>
          {/* <img
            className="result-image"
            src={codeIcon}
            onClick={this.handleCodeClick}
            hidden={!this.state.expanded}
            alt="Code"
          ></img> */}
          {this.props.deliverables ? (
            <img
              className="result-image"
              src={folderIcon}
              onClick={this.handleFolderClick}
              hidden={!this.state.expanded}
              alt="Deliverables"
            ></img>
          ) : null}
          <img
            className="result-image"
            src={trashIcon}
            onClick={this.handleTrashClick}
            hidden={!this.state.expanded || !this.props.admin_view}
            alt="Delete"
          ></img>
          <img
            className="result-image"
            src={editIcon}
            onClick={this.handleEditClick}
            hidden={!this.state.expanded || !this.props.admin_view}
            alt="Edit"
          ></img>

          <img
            className="result-image"
            src={menuIcon}
            hidden={this.state.expanded}
            alt="More"
          ></img>
        </div>

        {/* PROJECT DETAILS */}
        <div className={this.state.expanded
              ? "project-details"
              : "project-details-hidden"}>
          <div className="row-container" hidden={!this.state.expanded}>

            <div className="column">
              <div className="row-6 mb-2">
                <b>{this.props.course.name +
                  ", " +
                  this.props.course.quarter + 
                  " " + 
                  this.props.course.year }</b>
              </div>

              <div className="row-6 mb-2">
                <b>Course Code: </b> {this.props.course.code || "N/A"}
              </div>
              <div className="row-6">
                <div className="mb-2">
                  <b>Instructor: </b> {this.props.instructor || "N/A"}
                </div>
                <div className="mb-2 ml-3 needsIndent">
                  <b>Department: </b> {this.props.instructor_dept || "N/A"}
                </div>
                <div className="mb-2 ml-3 needsIndent">
                  <b>Email: </b> {this.props.instructor_email || "N/A"}
                </div>
              </div>
            </div>


            <div className="column">
              <div className="row-6">
                  <div className="mb-2" >
                    <b>Partner: </b> {this.props.partner_name || "N/A"}
                  </div>
                  <div className="mb-2 ml-3 needsIndent">
                    <b>Department: </b> {this.props.partner_department || "N/A"}
                  </div>
                  <div className="mb-2 ml-3 needsIndent">
                    <b>Department Email: </b> {this.props.partner_email || "N/A"}
                  </div>
                  <div className="mb-2 ml-3 needsIndent">
                    <b>Website: </b>
                    <a href={this.props.company_website || "N/A"}>
                      {this.props.company_website || "N/A"}
                    </a>
                </div>
              </div>
              
              <div className="row-6">
                  <div className="mb-2" >
                    <b>Primary Contact: </b> {this.props.primary_contact || "N/A"}
                  </div>
                  <div className="mb-2 ml-3 needsIndent">
                    <b>Email: </b> {this.props.primary_contact_email || "N/A"}
                  </div>
              </div>
            </div>
          </div>

          {/* VIDEO SECTION */}
          <div className="video-row" hidden={!this.state.expanded}>

            <div className="videoColumnFull">
              <div className="video-container">
                {/* only render the video element if a video exists */}
                {this.props.videos && this.props.videos.length > 0 ? (
                  <video
                    src={this.props.videos}
                    controls={true}
                    autoPlay={false}
                  ></video>
                ) : null}
              </div>
            </div>
          </div>


          <div className="row-container" hidden={!this.state.expanded}>

            <div className="columnFull">
              <div className="row-6 mb-2">
                <b>Overview: </b> {this.props.overview}
              </div>
            </div>
          </div>

          <div className="row-container" hidden={!this.state.expanded}>
            <div className="column">
              <div className="mb-2">
                  <b>Student Team Members: </b>
                  <div className="ml-3">
                    {this.props.team_members.map((member, index) => (
                      <span key={index} className="needsIndent">
                        {member.name || "N/A"}, {member.field3 || "N/A"}
                        {member.email && `, ${member.email}`}
                        <br />
                      </span>
                    ))}
                  </div>
              </div>     
            </div>

            <div className="column">
              <div className="mb-2">
                <b>Partner Representatives: </b>
                <div className="ml-3">
                  {this.props.representatives.map(
                    (representative, index) => (
                      <span key={index} class="needsIndent">
                        {representative.name || "N/A"}, {representative.field3 || "N/A"}
                        {representative.email && `, ${representative.email}`}
                        <br />
                      </span>
                    )
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="row-container" hidden={!this.state.expanded}>
            <div style={{ width: '25em', margin: '0 auto', textAlign: 'center' }}>
              <Button variant="primary" className="site-button">
                <Link to={`/project/${encodeURIComponent(this.props.project_name)}`} style={{ textDecoration: 'none', color: 'black' }}>
                  Page View
                </Link>
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ProjectResult;
