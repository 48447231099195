// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
    background-color: #eee;
    border-radius: 10px;
    padding: 10px;
    -webkit-user-select: none;
            user-select: none;
    font: 15px;
    font-weight: bold;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.title.expanded {
    border-radius: 10px 10px 0 0;
}

.input-fields {
    background-color: #fff;
    border: 2px #eee solid;
    border-radius: 0 0 0.5vw 0.5vw;
    padding: 10px 10px 3px 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.input-fields.expanded {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.person-row {
    border-color: #EABC24;
    margin-bottom: 7px; /* Add margin-bottom for spacing between rows */
}
`, "",{"version":3,"sources":["webpack://./src/css/PeopleInput.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;IACb,yBAAiB;YAAjB,iBAAiB;IACjB,UAAU;IACV,iBAAiB;IACjB,wCAAwC;AAC5C;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,sBAAsB;IACtB,sBAAsB;IACtB,8BAA8B;IAC9B,2BAA2B;IAC3B,wCAAwC;AAC5C;;AAEA;IACI,4BAA4B;IAC5B,6BAA6B;AACjC;;AAEA;IACI,qBAAqB;IACrB,kBAAkB,EAAE,+CAA+C;AACvE","sourcesContent":[".title {\r\n    background-color: #eee;\r\n    border-radius: 10px;\r\n    padding: 10px;\r\n    user-select: none;\r\n    font: 15px;\r\n    font-weight: bold;\r\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.title.expanded {\r\n    border-radius: 10px 10px 0 0;\r\n}\r\n\r\n.input-fields {\r\n    background-color: #fff;\r\n    border: 2px #eee solid;\r\n    border-radius: 0 0 0.5vw 0.5vw;\r\n    padding: 10px 10px 3px 10px;\r\n    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);\r\n}\r\n\r\n.input-fields.expanded {\r\n    border-bottom-left-radius: 0;\r\n    border-bottom-right-radius: 0;\r\n}\r\n\r\n.person-row {\r\n    border-color: #EABC24;\r\n    margin-bottom: 7px; /* Add margin-bottom for spacing between rows */\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
