// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.needsIndent{
  padding-left: 1.4em;
}

.column {
  flex: 1 1; 
  display: flex;
  flex-direction: column;
}

.columnFull {
  display: flex;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.videoColumnFull {
  display: flex;
  width: 50%;
  height: 50%;
  /* overflow: auto; */
}

.row-container {
  margin-top: 0.7em;
  display: flex;
  flex-direction: row;
  gap: 16px; 
  padding-left: 1em;
  padding-right: 1em;
}

.video-row {
  margin-top: 0.7em;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.video-container {
  flex: 1 1;
  display: flex;
  flex-direction: column;
}

.video-container video {
  max-width: 100%;
  height: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/css/ProjectResults.css"],"names":[],"mappings":";AACA;EACE,mBAAmB;AACrB;;AAEA;EACE,SAAO;EACP,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,YAAY;EACZ,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,UAAU;EACV,WAAW;EACX,oBAAoB;AACtB;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,SAAS;EACT,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,mBAAmB;AACrB;;AAEA;EACE,SAAO;EACP,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,eAAe;EACf,YAAY;AACd","sourcesContent":["\r\n.needsIndent{\r\n  padding-left: 1.4em;\r\n}\r\n\r\n.column {\r\n  flex: 1; \r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.columnFull {\r\n  display: flex;\r\n  width: 100%;\r\n  height: 100%;\r\n  overflow: auto;\r\n}\r\n\r\n.videoColumnFull {\r\n  display: flex;\r\n  width: 50%;\r\n  height: 50%;\r\n  /* overflow: auto; */\r\n}\r\n\r\n.row-container {\r\n  margin-top: 0.7em;\r\n  display: flex;\r\n  flex-direction: row;\r\n  gap: 16px; \r\n  padding-left: 1em;\r\n  padding-right: 1em;\r\n}\r\n\r\n.video-row {\r\n  margin-top: 0.7em;\r\n  display: flex;\r\n  flex-direction: row;\r\n  justify-content: center;\r\n  align-items: center;\r\n}\r\n\r\n.video-container {\r\n  flex: 1;\r\n  display: flex;\r\n  flex-direction: column;\r\n}\r\n\r\n.video-container video {\r\n  max-width: 100%;\r\n  height: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
