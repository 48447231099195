import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  Modal,
  Button,
  InputGroup,
  FormControl,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import "./css/EditModal.css";
import ProjectResult from "./ResultsPage.jsx";
import CourseInput from "./CourseInput.jsx";
import PeopleInput from "./PeopleInput.jsx";

function EditModal({ show, onClose, onSubmit, projectData }) {
  const initialProjectDetails = {
    projectName: "",
    team_members: [],
    representatives: [],
    partner: "",
    website: "",
    department: "",
    email: "",
    instructorName: "",
    instructorEmail: "",
    instructorDept: "",
    primaryContactName: "",
    primaryContactEmail: "",
    primaryContactJobTitle: "",
    overview: "",
    course: { name: "", code: "", quarter: "", year: "" },
  };

  const [isLoading, setIsLoading] = useState(false);

  const [projectDetails, setProjectDetails] = useState(initialProjectDetails);
  const [repositoryFile, setRepositoryFile] = useState();
  const [deliverableFile, setDeliverableFile] = useState();
  const [videoFile, setVideoFile] = useState();
  const [partnerErrors, setPartnerErrors] = useState({});
  const [instructorErrors, setInstructorErrors] = useState({});
  const [primaryContactErrors, setPrimaryContactErrors] = useState({});
  const [courseErrors, setCourseErrors] = useState({});
  const [teamMembersErrors, setTeamMembersErrors] = useState({});
  const [representativesErrors, setRepresentativesErrors] = useState({});
  const [overviewError, setOverviewError] = useState("");
  const [projectNameError, setProjectNameError] = useState(""); 

  useEffect(() => {
    if (projectData) {
      setProjectDetails({
        projectName: projectData.project_name,
        partner: projectData.partner_name,
        website: projectData.company_website,
        department: projectData.partner_department,
        email: projectData.partner_email,
        instructorName: projectData.instructor,
        instructorEmail: projectData.instructor_email,
        instructorDept: projectData.instructor_dept,
        primaryContactName: projectData.primary_contact,
        primaryContactEmail: projectData.primary_contact_email,
        primaryContactJobTitle: projectData.primary_contact_jt,
        repoLink: projectData.repo_link,
        overview: projectData.overview,
        team_members: projectData.team_members || [],
        representatives: projectData.representatives || [],
        repositoryKey: projectData.code_repository_key,
        deliverableKey: projectData.deliverables_key,
        videoKey: projectData.video_key,
        course: projectData.course || {
          name: "",
          code: "",
          quarter: "",
          year: "",
        },
      });
    } else {
      setProjectDetails(initialProjectDetails);
    }
    console.log("editmodal");
    console.log(projectData);
  }, [projectData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProjectDetails((prev) => ({ ...prev, [name]: value }));
    console.log(projectDetails);
  };

  const handleCourseChange = (course) => {
    setProjectDetails((prev) => ({ ...prev, course }));
    const courseValidationErrors = validateCourse(course);
    setCourseErrors(courseValidationErrors);
  };

  const handlePeopleChange = (type, people) => {
    setProjectDetails((prev) => ({ ...prev, [type]: people }));
    const peopleValidationErrors = validatePeople(type, people);
    if (type === "team_members") {
      setTeamMembersErrors(peopleValidationErrors);
    } else if (type === "representatives") {
      setRepresentativesErrors(peopleValidationErrors);
    }
  };

  const handleFileChange = (e, fieldName) => {
    const file = e.target.files[0];
    if (fieldName === "deliverableFile") {
      setDeliverableFile(file);
    } else if (fieldName === "repositoryFile") {
      setRepositoryFile(file);
    } else if (fieldName === "videoFile") {
      setVideoFile(file);
    }
  };

  const handleClose = () => {
    setProjectDetails(initialProjectDetails);
    setRepositoryFile(null);
    setDeliverableFile(null);
    setVideoFile(null);
    onClose();
  };

  const validateCourse = (course) => {
    const newErrors = {};
    if (!course.name) {
      newErrors.name = "Course name is required";
    } else if (!/^[A-Za-z0-9\s]+\s\d+$/.test(course.name)) {
      newErrors.name = "Course name must be characters followed by a space and a number.";
    }
    if (!course.code) {
      newErrors.code = "Course code is required";
    }
    if (!course.quarter) {
      newErrors.quarter = "Quarter is required";
    }
    if (!course.year) {
      newErrors.year = "Year is required";
    }
    return newErrors;
  };

  const validateName = (name) => {
    const nameParts = name.trim().split(" ");
    if (nameParts.length === 2) {
      return true;
    }
    return false;
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePeople = (type, people) => {
    const errorTypes = new Set();
    const uniqueErrors = {};
  
    if (people.length === 0) {
      uniqueErrors[type] = { 0: { name: "At least one member is required" } };
      return uniqueErrors;
    }
  
    people.forEach((person, index) => {
      if (!person.name || !validateName(person.name)) {
        if (!errorTypes.has('name')) {
          errorTypes.add('name');
          if (!uniqueErrors[type]) uniqueErrors[type] = {};
          if (!uniqueErrors[type][index]) uniqueErrors[type][index] = {};
          uniqueErrors[type][index].name = "Name is required, must have a first and last name";
        }
      }
      if (!validateEmail(person.email)) {
        if (!errorTypes.has('email')) {
          errorTypes.add('email');
          if (!uniqueErrors[type]) uniqueErrors[type] = {};
          if (!uniqueErrors[type][index]) uniqueErrors[type][index] = {};
          uniqueErrors[type][index].email = "Valid email is required";
        }
      }
      if (!person.field3) {
        if (!errorTypes.has('field3')) {
          errorTypes.add('field3');
          if (!uniqueErrors[type]) uniqueErrors[type] = {};
          if (!uniqueErrors[type][index]) uniqueErrors[type][index] = {};
          uniqueErrors[type][index].field3 = "Field is required";
        }
      }
    });
  
    return uniqueErrors;
  };
  

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
  
    const courseValidationErrors = validateCourse(projectDetails.course);
    setCourseErrors(courseValidationErrors);
  
    const teamMembersValidationErrors = validatePeople("team_members", projectDetails.team_members);
    setTeamMembersErrors(teamMembersValidationErrors);
  
    const representativesValidationErrors = validatePeople("representatives", projectDetails.representatives);
    setRepresentativesErrors(representativesValidationErrors);

    // Validation for project name and overview
    let projectNameValid = true;
    let overviewValid = true;
    if (!projectDetails.projectName) {
      setProjectNameError("Project name is required");
      projectNameValid = false;
    } else {
      setProjectNameError("");
    }

    if (!projectDetails.overview) {
      setOverviewError("Project overview is required");
      overviewValid = false;
    } else {
      setOverviewError("");
    }
  
    // Validation for partner information fields
    const partnerErrors = {};
    if (!projectDetails.partner) {
      partnerErrors.partner = "Partner is required";
    }
    if (!projectDetails.website) {
      partnerErrors.website = "Website is required";
    }
    if (!projectDetails.department) {
      partnerErrors.department = "Department is required";
    }
    if (!projectDetails.email || !validateEmail(projectDetails.email)) {
      partnerErrors.email = "Valid email is required";
    }
  
    // Validation for instructor information fields
    const instructorErrors = {};
    if (!projectDetails.instructorName || !validateName(projectDetails.instructorName)) {
      instructorErrors.instructorName = "Name is required, must have a first and last name";
    }
    if (!projectDetails.instructorEmail || !validateEmail(projectDetails.instructorEmail)) {
      instructorErrors.instructorEmail = "Valid email is required";
    }
    if (!projectDetails.instructorDept) {
      instructorErrors.instructorDept = "Department is required";
    }
  
    // Validation for primary contact information fields
    const primaryContactErrors = {};
    if (!projectDetails.primaryContactName || !validateName(projectDetails.primaryContactName)) {
      primaryContactErrors.primaryContactName = "Name is required, must have a first and last name";
    }
    if (!projectDetails.primaryContactEmail || !validateEmail(projectDetails.primaryContactEmail)) {
      primaryContactErrors.primaryContactEmail = "Valid email is required";
    }
    if (!projectDetails.primaryContactJobTitle) {
      primaryContactErrors.primaryContactJobTitle = "Job title is required";
    }
  
    setPartnerErrors(partnerErrors);
    setInstructorErrors(instructorErrors);
    setPrimaryContactErrors(primaryContactErrors);
  
    // if any errors exist, prevent form submission
    if (
      !projectNameValid ||
      !overviewValid ||
      Object.keys(courseValidationErrors).length > 0 ||
      Object.keys(teamMembersValidationErrors).length > 0 ||
      Object.keys(representativesValidationErrors).length > 0 ||
      Object.keys(partnerErrors).length > 0 ||
      Object.keys(instructorErrors).length > 0 ||
      Object.keys(primaryContactErrors).length > 0
    ) {
      console.log("errors exist still");
      setIsLoading(false);
      return;
    }
  
    // if no errors, submit form
    const formData = new FormData();
    const projectDetailsCopy = {
      ...projectDetails,
      team_members: JSON.stringify(projectDetails.team_members),
      representatives: JSON.stringify(projectDetails.representatives),
      course: JSON.stringify(projectDetails.course),
    };
  
    Object.keys(projectDetailsCopy).forEach((key) => {
      formData.append(key, projectDetailsCopy[key]);
    });
  
    if (repositoryFile) formData.append("repositoryFile", repositoryFile);
    if (deliverableFile) formData.append("deliverableFile", deliverableFile);
    if (videoFile) formData.append("videoFile", videoFile);
  
    try {
      if (projectData && projectData.ID && projectData.project_ID) {
        await onSubmit(projectData.ID, projectData.project_ID, formData);
      } else {
        await onSubmit(0, 1, formData);
      }
    } catch (error) {
      console.error("Error during the save operation:", error);
      alert("Failed to save project due to network or server error");
    } finally {
      setIsLoading(false);
      if (onClose) onClose();
    }
  };
    

  return (
    <Modal show={show} onHide={handleClose} size="lg" centered>
      {isLoading && <div className="dim-overlay"></div>}
      <Form onSubmit={handleSubmit}>
        <Modal.Header closeButton className="modal-header">
          <Modal.Title>
            {projectDetails.projectName || "Add New Project"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <InputGroup className="mb-1">
                <InputGroup.Text>Project Name: </InputGroup.Text>
                <FormControl
                  name="projectName"
                  value={projectDetails.projectName}
                  onChange={handleInputChange}
                />
              </InputGroup>
              <p className="text-danger">
                {projectNameError && <div className="error">{projectNameError}</div>}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-1">
              <CourseInput
                course={projectDetails.course}
                onCourseChange={handleCourseChange}
              />
              <p className="text-danger">
                {courseErrors.name && <div className="error">{courseErrors.name}</div>}
                {courseErrors.code && <div className="error">{courseErrors.code}</div>}
                {courseErrors.quarter && <div className="error">{courseErrors.quarter}</div>}
                {courseErrors.year && <div className="error">{courseErrors.year}</div>}
              </p>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="mb-1">
              <PeopleInput
                title="Team Members"
                validateName={validateName}
                validateEmail={validateEmail}
                placeholders={{ name: "Full name ex) John Smith", email: "Email", field3: "Major" }}
                group={projectDetails.team_members}
                onPeopleChange={(people) => handlePeopleChange("team_members", people)}
              />
              <p className="text-danger">
                {teamMembersErrors.team_members &&
                  Object.keys(teamMembersErrors.team_members).map((index) => (
                    <div key={index}>
                      {teamMembersErrors.team_members[index]?.name && (
                        <div className="error">{teamMembersErrors.team_members[index].name}</div>
                      )}
                      {teamMembersErrors.team_members[index]?.email && (
                        <div className="error">{teamMembersErrors.team_members[index].email}</div>
                      )}
                      {teamMembersErrors.team_members[index]?.field3 && (
                        <div className="error">{teamMembersErrors.team_members[index].field3}</div>
                      )}
                    </div>
                  ))}
              </p>
            </Col>
          </Row>


          <Row>
            <Form.Label>Partner Information: </Form.Label>
            <Col md={6}>
              <InputGroup>
                <InputGroup.Text>Partner: </InputGroup.Text>
                <FormControl
                  name="partner"
                  value={projectDetails.partner}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </Col>
            <Col md={6}>
              <InputGroup>
                <InputGroup.Text>Website: </InputGroup.Text>
                <FormControl
                  name="website"
                  value={projectDetails.website}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <InputGroup className="mb-1">
                <InputGroup.Text>Department: </InputGroup.Text>
                <FormControl
                  name="department"
                  value={projectDetails.department}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </Col>
            <Col md={6}>
              <InputGroup className="mb-1">
                <InputGroup.Text>Department Email: </InputGroup.Text>
                <FormControl
                  type="email"
                  name="email"
                  value={projectDetails.email}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </Col>
            <p className="text-danger">
              {partnerErrors.partner && <div className="error">{partnerErrors.partner}</div>}
              {partnerErrors.website && <div className="error">{partnerErrors.website}</div>}
              {partnerErrors.department && <div className="error">{partnerErrors.department}</div>}
              {partnerErrors.email && <div className="error">{partnerErrors.email}</div>}
            </p>
          </Row>
          
          <Row>
            <Col md={12}>
              <PeopleInput
                title="Representatives"
                validateName={validateName}
                validateEmail={validateEmail}
                placeholders={{
                  name: "Full name ex) John Smith",
                  email: "Email",
                  field3: "Job title",
                }}
                group={projectDetails.representatives}
                onPeopleChange={(people) =>
                  handlePeopleChange("representatives", people)
                }
              />
              <p className="text-danger">
                {representativesErrors.representatives &&
                  Object.keys(representativesErrors.representatives).map((index) => (
                    <div key={index}>
                      {representativesErrors.representatives[index]?.name && (
                        <div className="error">{representativesErrors.representatives[index].name}</div>
                      )}
                      {representativesErrors.representatives[index]?.email && (
                        <div className="error">{representativesErrors.representatives[index].email}</div>
                      )}
                      {representativesErrors.representatives[index]?.field3 && (
                        <div className="error">{representativesErrors.representatives[index].field3}</div>
                      )}
                    </div>
                  ))}
              </p>
            </Col>
          </Row>

          <Row className="mb-1">
            <Col>{/* This column intentionally left blank for spacing. */}</Col>
          </Row>
          <Row className="mb-1">
            <Col>{/* This column intentionally left blank for spacing. */}</Col>
          </Row>

          <Row>
            <Form.Label>Instructor Information: </Form.Label>
            <Col md={4}>
              <InputGroup className="mb-1">
                <FormControl
                  placeholder="Name"
                  name="instructorName"
                  value={projectDetails.instructorName}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </Col>
            <Col md={4}>
              <InputGroup className="mb-1">
                <FormControl
                  placeholder="Email"
                  type="email"
                  name="instructorEmail"
                  value={projectDetails.instructorEmail}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </Col>
            <Col md={4}>
              <InputGroup className="mb-1">
                <FormControl
                  placeholder="Department"
                  type="text"
                  name="instructorDept"
                  value={projectDetails.instructorDept}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </Col>
            <p className="text-danger">
              {instructorErrors.instructorName && <div className="error">{instructorErrors.instructorName}</div>}
              {instructorErrors.instructorEmail && <div className="error">{instructorErrors.instructorEmail}</div>}
              {instructorErrors.instructorDept && <div className="error">{instructorErrors.instructorDept}</div>}
            </p>
          </Row>

          <Row>
            <Form.Label>Primary Contact Information: </Form.Label>
            <Col md={4}>
              <InputGroup className="mb-1">
                <FormControl
                  placeholder="Name"
                  name="primaryContactName"
                  value={projectDetails.primaryContactName}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </Col>
            <Col md={4}>
              <InputGroup className="mb-1">
                <FormControl
                  placeholder="Email"
                  type="email"
                  name="primaryContactEmail"
                  value={projectDetails.primaryContactEmail}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </Col>
            <Col md={4}>
              <InputGroup className="mb-1">
                <FormControl
                  placeholder="Job Title"
                  type="text"
                  name="primaryContactJobTitle"
                  value={projectDetails.primaryContactJobTitle}
                  onChange={handleInputChange}
                />
              </InputGroup>
            </Col>
            <p className="text-danger">
              {primaryContactErrors.primaryContactName && <div className="error">{primaryContactErrors.primaryContactName}</div>}
              {primaryContactErrors.primaryContactEmail && <div className="error">{primaryContactErrors.primaryContactEmail}</div>}
              {primaryContactErrors.primaryContactJobTitle && <div className="error">{primaryContactErrors.primaryContactJobTitle}</div>}
            </p>
          </Row>

          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Repository Zip File: </Form.Label>
                <Form.Control
                  type="file"
                  name="repositoryFile"
                  accept=".zip"
                  onChange={(e) => handleFileChange(e, "repositoryFile")}
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Deliverables Zip File: </Form.Label>
                <Form.Control
                  type="file"
                  name="deliverableFile"
                  accept=".zip"
                  onChange={(e) => handleFileChange(e, "deliverableFile")}
                />
              </Form.Group>
            </Col>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Video File</Form.Label>
                <Form.Control
                  type="file"
                  name="videoFile"
                  accept=".mp4,.mov"
                  onChange={(e) => handleFileChange(e, "videoFile")}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row>
            <Col md={12}>
              <Form.Group>
                <Form.Label>Overview: </Form.Label>
                <Form.Control
                  as="textarea"
                  rows={3}
                  placeholder="Type overview here. Use #'s to tag projects! ex) This project uses #artificialintelligence"
                  name="overview"
                  value={projectDetails.overview}
                  onChange={handleInputChange}
                />
              </Form.Group>
              <p className="text-danger">
                {overviewError && <div className="error">{overviewError}</div>}
              </p>
            </Col>
          </Row>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" type="submit">
            Save
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}

export default EditModal;

