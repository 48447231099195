// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.page-selector{
    font-family: "Montserrat";
}

.page-selector-button.selected-page-button{
    background-color: #FECC07;
}

.page-selector-button.selected-page-button:hover{
    background-color: #FFDD45;
}`, "",{"version":3,"sources":["webpack://./src/css/PageNavigatonBar.css"],"names":[],"mappings":";AACA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,yBAAyB;AAC7B","sourcesContent":["\r\n.page-selector{\r\n    font-family: \"Montserrat\";\r\n}\r\n\r\n.page-selector-button.selected-page-button{\r\n    background-color: #FECC07;\r\n}\r\n\r\n.page-selector-button.selected-page-button:hover{\r\n    background-color: #FFDD45;\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
