import React, { useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import './css/ResultsPage.css';
import ProjectResult from './ProjectResult';
import { PageNavigationBar, RESULTS_PER_PAGE } from './PageNavigationBar';
import FiltersModal from './FiltersModal';
import axios from 'axios';

function ResultsPage() {
  // Search bar state and handlers
  const [searchParams, setSearchParams] = useSearchParams();
  const [projectTitle, setProjectTitle] = useState(searchParams.get("q") ?? "");
  const [showFilters, setShowFilters] = useState(false);
  const [filterData, setFilterData] = useState({
    exactMatch: searchParams.get("exact_match") ? true : false,
    tags: searchParams.get("tags") ? searchParams.get("tags").split(",") : [],
    quarter: searchParams.get("quarter") ?? "",
    year: searchParams.get("year") ?? "",
    department: searchParams.get("department") ?? "",
    courseNumber: searchParams.get("course_number") ?? "",
    instructor: searchParams.get("instructor") ?? "",
    courseCode: searchParams.get("course_code") ?? "",
  });

  // const handleApplyFilters = (filters) => {
  //   setFilterData(filters);
  // };

  const handleOpenFilters = () => setShowFilters(true);
  const handleCloseFilters = () => setShowFilters(false);

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSearch();
    }
  };

  const navigate = useNavigate();
  const handleSearch = () => {
    let url = "/results?";
    let url_components = [];
    if (projectTitle !== "") {
      url_components.push(`q=${encodeURIComponent(projectTitle)}`);
    }
    if (filterData.exactMatch) {
      url_components.push("exact_match=true");
    }
    if (filterData.tags.length > 0) {
      url_components.push(`tags=${encodeURIComponent(filterData.tags.join(","))}`);
    }
    if (filterData.quarter !== "") {
      url_components.push(`quarter=${encodeURIComponent(filterData.quarter)}`);
    }
    if (filterData.year !== "") {
      url_components.push(`year=${encodeURIComponent(filterData.year)}`);
    }
    if (filterData.department !== "") {
      url_components.push(`department=${encodeURIComponent(filterData.department)}`);
    }
    if (filterData.courseNumber !== "") {
      url_components.push(`course_number=${encodeURIComponent(filterData.courseNumber)}`);
    }
    if (filterData.instructor !== "") {
      url_components.push(`instructor=${encodeURIComponent(filterData.instructor)}`);
    }
    if (filterData.courseCode !== "") {
      url_components.push(`course_code=${encodeURIComponent(filterData.courseCode)}`);
    }

    url += url_components.join("&");
    navigate(url);
  };

  // Results page state and handlers
  const [results, setResults] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);

  const fetchCoursesAndProjects = async () => {
    try {
      // construct url for get request
      let getURL = "https://icscapstonearchive.org/api/projects";
      getURL += "/" + encodeURIComponent(searchParams.get("q"));
      const params = ["exact_match", "tags", "quarter", "year", "department", "course_number", "instructor", "course_code"];
      for (let i = 0; i < params.length; i++) {
        getURL += "/" + params[i] + "/";
        getURL += encodeURIComponent(searchParams.get(params[i]));
      }

      // GET request to fetch projects
      const response = await axios.get(getURL);
      if (response && Array.isArray(response.data)) {
        console.log(response.data);
        const projectResults = response.data.map((project) => ({          
          deliverables: project.deliverables,
          ID: project.course.id, // course ID
          project_ID: project._id,
          project_name: project.project_name,
          course: {
            name: project.course.course_name,
            quarter: project.course.quarter,
            year: project.course.year,
            code: project.course.course_code,
          },
          instructor: `${project.course.professor.name.first} ${project.course.professor.name.last}`,
          instructor_dept: project.course.professor.department,
          instructor_email: project.course.professor.email,
          partner_name: project.partner.company_name,
          partner_department: project.primary_contact.department,
          primary_contact: `${project.primary_contact.name.first} ${project.primary_contact.name.last}`,
          primary_contact_email: project.primary_contact.email,
          company_website: project.partner.company_website,
          videos: project.videos,
          overview: project.overview,
          team_members: project.team_members.map((student) => ({
            name: `${student.name.first} ${student.name.last}`,
            field3: student.major,
            // email: student.email
          })),
          representatives: project.representatives.map((rep) => ({
            name: `${rep.name.first} ${rep.name.last}`,
            field3: rep.job_title,
            // email: rep.email,
          })),
          tags: project.tags,
          code_repository: project.code_repository,
          partner_email: project.partner.email,
        }));
        setResults(projectResults);
      } else {
        console.error("Error fetching courses: Invalid response data");
      }
    } catch (error) {
      console.error("Error fetching courses:", error);
    }
  };

  useEffect(() => {
    fetchCoursesAndProjects();
  }, [searchParams]);

  return (
    <div className="results-page mt-2">
      <div className="search-container mt-0">
        <button className="filter-button" onClick={handleOpenFilters}></button>
        <FiltersModal show={showFilters} onHide={handleCloseFilters} filterData={filterData} handleApplyFilters={setFilterData} />
        <input
          type="search"
          className="search-bar"
          placeholder="Project Title"
          value={projectTitle}
          onChange={(x) => setProjectTitle(x.target.value)}
          onKeyDown={handleKeyDown}
        />
        <button className="search-button" onClick={handleSearch}></button>
      </div>
      <h3><b>Search Results</b></h3>
      <p>Click to view projects below:</p>
      <div>
        {results.slice((currentPage * RESULTS_PER_PAGE), ((currentPage + 1) * RESULTS_PER_PAGE)).map((result, index) => (
          <div className="result-container" key={index}>
            {createProjectResult(result)}
          </div>
        ))}
      </div>
      <PageNavigationBar numResults={results.length} currentPage={currentPage} setCurrentPage={setCurrentPage}></PageNavigationBar>
    </div>
  );
}

function createProjectResult(projectData) {
  return <ProjectResult {...projectData} admin_view={false}></ProjectResult>;
}

export default ResultsPage;
