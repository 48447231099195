import logo from './logo.svg';
import './css/App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import HomePage from './HomePage';
import CurrentProjects from './CurrentProjects';
import EditModal from './EditModal';
import FiltersModal from './FiltersModal';
import Login from './Login';
import ResultsPage from './ResultsPage';
import NavBar from './NavBar';
import Testing from './Testing';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useState, useEffect } from 'react';
import ProjectDetailPage from './ProjectDetailPage.jsx';
import ProtectedRoute from './ProtectedRoute.jsx'; // Import the ProtectedRoute component

function App() {
  const [loggedInAnswer, setLoggedInAnswer] = useState(false);
  useEffect(() => {

    const isLoggedIn = /*localStorage*/sessionStorage.getItem('isLoggedIn') === 'true';
    console.log("isLoggedIn value App " + isLoggedIn)
    setLoggedInAnswer(isLoggedIn);

    window.onbeforeunload = () => {
      sessionStorage.removeItem('isLoggedIn');
    };
  }, []);

  return (
    <Router>
      <NavBar isLoggedIn={loggedInAnswer} setLoggedInAnswer={setLoggedInAnswer} />
      <Routes>
        <Route path="/" element={<HomePage />} />
        {/* <Route path="/projects" element={<CurrentProjects />} /> */}
        <Route
          path="/projects"
          element={
            <ProtectedRoute isLoggedIn={loggedInAnswer}>
              <CurrentProjects />
            </ProtectedRoute>
          }
        />
        <Route path="/login" element={<Login setLoggedInAnswer={setLoggedInAnswer} />} />
        <Route path="/results" element={<ResultsPage />} />
        <Route path="/project/:project_name" element={<ProjectDetailPage />} />
        {/* <Route path="/Testing" element={<Testing />} /> */}
      </Routes>
    </Router>
  );
}

export default App;