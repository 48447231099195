import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal'
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import ToggleButton from 'react-bootstrap/ToggleButton';
import './css/FiltersModal.css'

// Filters Modal
// used with useState in HomePage for visibility
// note: if no input, number inputs will be kept as empty strings
function FiltersModal({ show, onHide, filterData, handleApplyFilters }) {

  const [exactMatch, setExactMatch] = useState(true);
  const handleToggleExactMatch = () => setExactMatch(!exactMatch);

  const [selectedTags, setSelectedTags] = useState([]);
  const tags = ["Artificial Intelligence", "Databases", "Web Development", "Mobile Development", "Graphics", "Networks", "IoT"];
  // adds new tag to selected tags array if selected
  // removes tag from selected tags array if unselected
  const handleTagToggle = (tag) => { selectedTags.includes(tag) ? setSelectedTags(selectedTags.filter(item => item !== tag)) : setSelectedTags([...selectedTags, tag]) };

  const [selectedQuarter, setSelectedQuarter] = useState("");
  const quarters = ["Fall", "Winter", "Spring", "Summer"];
  const handleQuarterSelect = (quarter) => { selectedQuarter === quarter ? setSelectedQuarter("") : setSelectedQuarter(quarter) }


  const [selectedDepartment, setSelectedDepartment] = useState("");
  const departments = ["Computer Science", "Informatics", "Statistics"];
  const handleDepartmentSelect = (department) => { selectedDepartment === department ? setSelectedDepartment("") : setSelectedDepartment(department) }

  const [year, setYear] = useState("");
  const [courseNumber, setCourseNumber] = useState("");
  const [courseCode, setCourseCode] = useState("");
  const [instructor, setInstructor] = useState("");


  // update settings when APPLY is clicked
  const applyFilters = () => {
    handleApplyFilters({
      exactMatch: exactMatch,
      tags: selectedTags.map((tag) => tag.split(" ").join("").toLowerCase()), // convert tags to lowercase with no spaces
      quarter: selectedQuarter,
      year: year,
      department: selectedDepartment,
      courseNumber: courseNumber,
      instructor: instructor,
      courseCode: courseCode,
    });
    onHide();
  };

  // load saved settings when opened
  useEffect(() => {
    if (show) {
      setExactMatch(filterData.exactMatch);
      setSelectedTags(filterData.tags.map((selected_tag) => {
        for (const tag of tags) {
          console.log(tag.split(" ").join("").toLowerCase());
          console.log(selected_tag);
          if (tag.split(" ").join("").toLowerCase() == selected_tag) {
            return tag;
          }
        }
      }));
      setSelectedQuarter(filterData.quarter);
      setYear(filterData.year);
      setSelectedDepartment(filterData.department);
      setCourseNumber(filterData.courseNumber);
      setInstructor(filterData.instructor);
      setCourseCode(filterData.courseCode);
    }
  }, [show]);

  const resetFilters = () => {
    setExactMatch(true);
    setSelectedTags([]);
    setSelectedQuarter("");
    setYear("");
    setSelectedDepartment("");
    setCourseNumber("");
    setInstructor("");
    setCourseCode("");
  };

  return (
    <div>
      <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,700,0..1,-50..200" />
      <Modal show={show} onHide={onHide} size="xl">

        {/* Top Area */}
        <div className="text-center justify-content-center row align-items-center mt-4">
          <div className="col">
            <span className="material-symbols-outlined" id="closeBtn" onClick={onHide}>close</span>
          </div>
          <h1 className="col m-0" >
            Filters
          </h1>
          <div className="col">
            <span className="col m-3 p-2 clickable" onClick={resetFilters}>
              RESET
            </span>
          </div>
        </div>

        {/* Filters */}
        <div className="m-5 mb-4">
          {/* Exact Match */}
          <div className="row">
            <div className="col-10">
              <h3 className="mb-0">
                Exact Match
              </h3>
              <p>
                Find projects containing all of the selected tags below.
              </p>
            </div>
            <button
              className={`toggle-button ${exactMatch ? 'active' : ''}`}
              onClick={handleToggleExactMatch}
            >
              <div className="inner-circle" />
            </button>
          </div>
          {/* Include Tags */}
          <div>
            <div className="col">
              <h3 className="mb-1">
                Include Tags
              </h3>
              <p>
                Find projects with the selected tags below:
              </p>
            </div>
            <ButtonGroup toggle className="col" style={{ flexWrap: 'wrap' }}>
              {tags.map(tag => (
                <ToggleButton
                  key={tag}
                  type="checkbox"
                  id={`toggle-${tag.toLowerCase()}`}
                  variant={selectedTags.includes(tag) ? "primary" : "outline-primary"}
                  checked={selectedTags.includes(tag)}
                  onChange={() => handleTagToggle(tag)}
                  className={`m-1 rounded-pill selectable ${selectedTags.includes(tag) ? "selected-selectable" : ""}`}
                >
                  {tag}
                </ToggleButton>
              ))}
            </ButtonGroup>
          </div>
          <div className="row mt-3">
            {/* Quarter */}
            <div className="col">
              <h3>
                Quarter
              </h3>
              <ButtonGroup>
                {quarters.map(quarter => (
                  <ToggleButton
                    key={quarter}
                    type="checkbox"
                    id={`toggle-${quarter.toLowerCase()}`}
                    variant={selectedQuarter === quarter ? "primary" : "outline-primary"}
                    checked={selectedQuarter === quarter}
                    onChange={() => handleQuarterSelect(quarter)}
                    className={`m-1 rounded-pill selectable ${selectedQuarter === quarter ? "selected-selectable" : ""}`}
                  >
                    {quarter}
                  </ToggleButton>
                ))}
              </ButtonGroup>
            </div>
            {/* Year */}
            <div className="col">
              <h3>
                Year
              </h3>
              <div>
                <input
                  type="number"
                  value={year}
                  className="col m-1"
                  onChange={(x) => {setYear(x.target.value || "")}}
                >
                </input>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            {/* Department */}
            <div className="col">
              <h3>
                Department
              </h3>
              <ButtonGroup>
                {departments.map(department => (
                  <ToggleButton
                    key={department}
                    type="checkbox"
                    id={`toggle-${department.toLowerCase()}`}
                    variant={selectedDepartment === department ? "primary" : "outline-primary"}
                    checked={selectedDepartment === department}
                    onChange={() => handleDepartmentSelect(department)}
                    className={`m-1 rounded-pill selectable ${selectedDepartment === department ? "selected-selectable" : ""}`}
                  >
                    {department}
                  </ToggleButton>
                ))}
              </ButtonGroup>
            </div>
            {/* Course Number */}
            <div className="col">
              <h3>
                Course Number
              </h3>
              <div>
                <input
                type="number" placeholder="E.g. 117"
                value={courseNumber}
                className="col m-1"
                onChange={(x) => setCourseNumber(x.target.value || "")}
                >
                </input>
              </div>
            </div>
          </div>
          <div className="row mt-3">
            {/* Instructor */}
            <div className="col">
              <div>
                <h3 className="col mb-0">
                  Instructor
                </h3>
                <p className="col">
                  First or last name
                </p>
              </div>
              <div>
                <input value={instructor} className="col-9 m-1" onChange={(x) => setInstructor(x.target.value)}>
                </input>
              </div>
            </div>
            {/* Course Code */}
            <div className="col">
              <div>
                <h3 className="col">
                  Course Code
                </h3>
              </div>
              <div>
                <input
                type="number" placeholder="E.g. 36050"
                value={courseCode}
                className="col m-1"
                onChange={(x) => setCourseCode(x.target.value || "")}
                >
                </input>
              </div>
            </div>
          </div>
        </div>

        {/* Bottom Area */}
        <div className="text-center justify-content-center row align-items-center mt-3 ml-1 mr-1 mb-5">
          <div className="col">
            <span className="col m-4 p-2 clickable" onClick={onHide}>
              CANCEL
            </span>
          </div>
          {/* empty column for spacing */}
          <div className="col-4"></div>
          <div className="col">
            <span className="col m-4 p-2 clickable" onClick={applyFilters}>
              APPLY
            </span>
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default FiltersModal;