import React, { useState } from 'react';
import EditModal from './EditModal'; // Assuming EditModal is in the same directory

function Testing() {
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Dummy project data for testing
  const projectData = {
    projectName: 'Dummy Project',
    teamMembers: 'P1, P2, P3',
    partner: 'Amazon',
    website: 'https://www.amazon.com/',
    department: 'AWS',
    email: 'amazon@gmail.com',
    instructorName: 'Ziv',
    instructorEmail: 'Ziv@gmail.com',
    primaryContactName: 'Jeff Bezos',
    primaryContactEmail: 'Bezos@gmail.com',
    repoLink: 'repo.com',
    overview: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent id ornare diam, ut viverra nisi. Phasellus lobortis magna ut sem hendrerit, ac volutpat ipsum cursus. Quisque facilisis quam id mauris accumsan varius. In sollicitudin at eros eu dictum. Vestibulum a velit lectus. Sed eros mi, vulputate ut tortor porttitor, vehicula lobortis lacus. Nullam ut ex hendrerit, tristique nunc in, varius velit. Duis rhoncus ante id quam euismod scelerisque. Nullam non velit pellentesque sapien viverra placerat ac nec mauris. Proin lacinia dignissim nibh at malesuada. Suspendisse ornare egestas tincidunt.'
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <>
      <button onClick={openModal}>Edit</button>
      <EditModal show={isModalOpen} projectData={projectData} onClose={closeModal} />

    </>
  );
}

export default Testing;